import { AsideNav } from 'components/nav'
import { ownerRoutes } from 'const/owner-routes'
import { FC, ReactNode } from 'react'
import { Loader, MediaQuery, Outlet } from 'ui'
import { cn } from 'utils'
import styles from './navigation-container.module.scss'
import { useTransitionBetweenRoutes } from './use-transition-between-routes'

interface Props {
  navComponent?: typeof AsideNav
  children?: ReactNode
}

export const NavigationContainer: FC<Props> = ({ navComponent: Nav = AsideNav, children }) => {
  const isLoading = useTransitionBetweenRoutes(betweenTopRoutes)

  return (
    <div className={styles.container}>
      <MediaQuery.Desktop>
        <AsideNav className={styles.aside} />
      </MediaQuery.Desktop>
      <main
        className={cn(styles.main, isLoading && styles.loading)}
        aria-busy={isLoading ? 'true' : 'false'}
        aria-live="polite"
      >
        {children ?? <Outlet />}
      </main>
      {isLoading && <Loader className={styles.loader} />}
    </div>
  )
}

const betweenTopRoutes = (from: string, to: string) => {
  const fromOwner = from.startsWith(ownerRoutes.my)
  const toOwner = to.startsWith(ownerRoutes.my)
  if (fromOwner !== toOwner) return true
  const pathElementIndex = fromOwner ? 2 : 1
  const fromPathElement = from.split('/')[pathElementIndex]
  const toPathElement = to.split('/')[pathElementIndex]
  return fromPathElement !== toPathElement
}
