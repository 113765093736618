import { User, api, UserRole } from 'api/admin'
import { adminRoutes } from 'const/admin-routes'
import { LoaderFunctionArgs, Params, redirect } from 'react-router-dom'
import { Access, validateRoleAccess } from './access'

export function authorized<Data extends {}, P extends string = string>(
  access: Access,
  loader?: (params: { user: User; request: Request; params: Params<P> }) => Promise<Data> | Data,
) {
  return async (params: LoaderFunctionArgs) => {
    const user = await api.user.currentUser()
    if (!user) {
      const { pathname } = new URL(params.request.url)
      const search = new URLSearchParams({ next: pathname })
      throw redirect(`${adminRoutes.login}?${search.toString()}`)
    }
    if (!validateAdminAccess(user) || !validateRoleAccess(user, access)) {
      throw new Error(`You do not have access to this page. ${access} permission required.`)
    }
    if (!loader) return {}
    return (await loader({ ...params, user })) ?? {}
  }
}

export const validateAdminAccess = (user: User) =>
  [UserRole.Agent, UserRole.Owner, UserRole.Admin].some((role) => user.roles?.includes(role))
