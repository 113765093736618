import {
  Unit,
  AccountScore,
  Application,
  Lease,
  Owner,
  UnitPhoto,
  Property,
  LeaseChecklist,
  User,
  Ledger,
  UserDocument,
  Template,
  PaymentAccount,
  PropertyManager,
  Chat,
  UserFile,
  LeaseDraft,
} from 'api/admin'
import { UserSignature } from 'api/user-signature'
import { UNIT_PATHS } from 'const/unit-paths'
import { generatePath as gen } from 'react-router-dom'

const routes = {
  notFound: '/404',
  forbidden: '/forbidden',
  welcome: '/',
  login: '/login',
  signUp: '/login/sign-up',
  passwordRecovery: '/login/password-recovery',
  passwordReset: '/login/password-reset',

  // #region ACCOUNT
  account: '/account',
  accountSignatureView: 'view-signature/:type',
  getAccountSignatureView: (q: { type: UserSignature.Type }) => gen(routes.accountSignatureView, q),
  accountSignatureEdit: 'edit-signature/:type',
  getAccountSignatureEdit: (q: { type: UserSignature.Type }) => gen(routes.accountSignatureEdit, q),
  accountSignatureEditDraw: 'draw',
  accountSignatureEditType: 'type',
  accountSignatureEditLoad: 'load',
  accountSignatureReset: 'reset-signature',
  deleteAccount: '/account/delete',
  reloginAccount: '/account/relogin',
  // #endregion

  // #region COMMUNICATION
  communication: '/communication',
  communicationChat: ':chat_id',
  getCommunicationChat: (q: Chat.Id) => gen(routes.communicationChat, q),
  // #endregion

  // #region APPLICATIONS
  applications: '/applications',
  applicationsEdit: 'edit/:application_id',
  getApplicationsEdit: (q: Application.Id) => gen(routes.applicationsEdit, q),
  applicationsCancel: 'cancel/:application_id',
  getApplicationsCancel: (q: Application.Id) => gen(routes.applicationsCancel, q),
  applicationsMove: 'move/:application_id',
  getApplicationsMove: (q: Application.Id) => gen(routes.applicationsMove, q),
  applicationsDelete: 'delete/:application_id',
  getApplicationsDelete: (q: Application.Id) => gen(routes.applicationsDelete, q),
  applicationsReport: 'report-view/:application_id',
  getApplicationsReport: (q: Application.Id) => gen(routes.applicationsReport, q),
  getApplicationsReportDownload: (q: Application.Id) =>
    `${gen(routes.applicationsReport, q)}?download`,
  // #endregion
  // #region APPLICATION
  application: '/applications/:application_id',
  getApplication: (q: Application.Id) => gen(routes.application, q),
  applicationOverview: 'overview',
  applicationOverviewEdit: 'edit',
  applicationOverviewMove: 'move',
  applicationOverviewCancel: 'cancel',
  applicationOverviewDelete: 'delete',
  applicationOverviewLeaseDraftCreate: 'create-draft',
  applicationOverviewLeaseDraftPreview: 'preview-draft/:draft_id',
  getApplicationLeaseDraftPreview: (query: LeaseDraft.Id) =>
    `${gen(routes.applicationOverviewLeaseDraftPreview, query)}`,

  applicationReport: 'report',
  applicationReportDownload: 'report?download',

  applicationChat: 'chat',
  applicationChatCreate: 'create',

  // #endregion

  // #region DASHBOARD
  dashboard: '/dashboard',
  // #endregion

  // #region INVITATIONS
  invitations: '/invitations',
  getInvitations: (query: Record<string, string>) => withSearchParams(routes.invitations, query),
  // #endregion

  // #region UNITS
  units: '/units',
  unitCreate: 'create',
  ...UNIT_PATHS,
  unitsImportRM: 'import-rm',
  unitsImportURL: 'import-url',
  unitsEdit: 'edit/:unit_id',
  getUnitsEdit: (q: Unit.Id) => `/units/${gen(routes.unitsEdit, q)}`,
  unitsDelete: 'delete/:unit_id',
  getUnitsDelete: (q: Unit.Id) => `/units/${gen(routes.unitsDelete, q)}`,

  // #endregion
  // #region UNIT
  unitDetails: '/units/:unit_id',
  getUnitDetails: (q: Unit.Id) => gen(routes.unitDetails, q),
  getUnitApplications: (q: Unit.Id) => `${gen(routes.unitDetails, q)}/${routes.unitApplications}`,

  // #region  UNIT -> OVERVIEW
  unitDelete: 'delete',
  unitDetailsAddPhoto: 'add-photo',
  unitDetailsDelete: 'delete',
  unitDetailsDeletePhoto: 'gallery/:photo_id/delete',
  getUnitDetailsDeletePhoto: ({ photo_id }: Unit.Id & UnitPhoto.Id) =>
    gen(routes.unitDetailsDeletePhoto, { photo_id }),
  unitDetailsDetails: 'details',
  unitDetailsEdit: 'edit',
  unitDetailsFindLocalion: 'find-location',
  unitDetailsImportRM: 'import-rm',
  unitDetailsImportURL: 'import-url',
  unitDetailsLocalion: 'location',
  unitDetailsShare: 'details/share',
  unitDetailsViewPhoto: 'gallery/:photo_id',
  getUnitDetailsViewPhoto: (q: Unit.Id & UnitPhoto.Id) =>
    `${routes.getUnitDetails(q)}/${routes.unitDetailsDetails}/${gen(
      routes.unitDetailsViewPhoto,
      q,
    )}`,
  // #endregion
  // #region UNIT -> AGENTS
  unitDetailsAgents: 'agents',
  getUnitDetailsAgents: (query: Unit.Id) =>
    `${routes.getUnitDetails(query)}/${routes.unitDetailsAgents}`,
  unitDetailsAgentsManage: 'manage',
  // #endregion
  // #region  UNIT -> AUCTION
  unitDetailsAuction: 'auction',
  unitDetailsAuctionArchive: 'archive',
  unitDetailsAuctionDelete: 'delete',
  unitDetailsAuctionEdit: 'edit',
  unitDetailsAuctionReopen: 'reopen',
  unitDetailsAuctionClose: 'close/:application_id',
  getUnitDetailsCloseAuction: (data: Application.Id) => gen(routes.unitDetailsAuctionClose, data),
  unitDetailsAuctionCancelApplication: 'cancel-application/:application_id',
  getUnitDetailsAuctionCancelApplication: (q: Application.Id) =>
    gen(routes.unitDetailsAuctionCancelApplication, q),
  // #endregion
  // #region  UNTI -> OTHER
  unitApplications: 'applications',
  unitInvitedUsers: 'invited-users',
  unitDetailsLeases: 'leases',
  unitDetailsAuditLog: 'audit-log',
  // #endregion
  // #endregion

  // #region PROPERTIES
  properties: '/properties',
  propertiesCreate: '/properties/new',
  propertiesEdit: 'edit/:property_id',
  getPropertiesEdit: (q: Property.Id) => gen(routes.propertiesEdit, q),
  propertiesDelete: 'delete/:property_id',
  getPropertiesDelete: (q: Property.Id) => gen(routes.propertiesDelete, q),
  // #endregion
  // #region PROPERTY
  property: '/properties/:property_id',
  getPropertyDetails: (q: Property.Id) => gen(routes.property, q),
  propertyOverview: 'overview',
  propertyOverviewEdit: 'edit',
  propertyOverviewDelete: 'delete',
  propertyOverviewLocation: 'location',
  propertyOverviewFindLocation: 'find-location',
  getPropertyDetailsFindLocation: (q: Property.Id) =>
    `${routes.getPropertyDetails(q)}/${routes.propertyOverview}/${
      routes.propertyOverviewFindLocation
    }`,
  propertyUnits: 'units',
  propertyDetailsUnitEdit: 'edit/:unit_id',
  getPropertyDetailsUnitEdit: (q: Unit.Id) => gen(routes.propertyDetailsUnitEdit, q),
  propertyAuditLog: 'audit-log',

  // #endregion

  // #region TEMPLATES
  templates: '/templates',
  templatesCreate: 'create',
  templatesViewPdf: 'view-pdf/:template_id',
  getTemplatesViewPdf: (q: Template.Id) => gen(routes.templatesViewPdf, q),
  templatesEdit: 'edit/:template_id',
  getTemplatesEdit: (q: Template.Id) => gen(routes.templatesEdit, q),
  templatesDelete: 'delete/:template_id',
  getTemplatesDelete: (q: Template.Id) => gen(routes.templatesDelete, q),
  templatesClone: 'clone/:template_id',
  getTemplatesClone: (q: Template.Id) => gen(routes.templatesClone, q),

  // #endregion
  // #region TEMPLATE
  templateDetails: '/templates/:template_id',
  getTemplateDetails: (q: Template.Id) => gen(routes.templateDetails, q),
  templateDetailsOverview: 'overview',
  templateDetailsOverviewEdit: 'edit',
  templateDetailsOverviewClone: 'clone',
  templateDetailsOverviewDelete: 'delete',
  templateDetailsOverviewViewPdf: 'view-pdf',
  templateDetailsEditor: 'editor',
  getTemplateDetailsEditor: (q: Template.Id) =>
    `${gen(routes.templateDetails, q)}/${routes.templateDetailsEditor}`,

  // #endregion

  // #region USERS
  users: '/users',
  userCreate: 'new',
  usersEdit: 'edit/:user_id',
  getUsersEdit: (q: User.Id) => gen(routes.usersEdit, q),

  // #endregion
  // #region USER
  userDetails: '/users/:user_id',
  getUserDetails: (q: User.Id) => gen(routes.userDetails, q),
  // USER DETAILS -> OVERVIEW
  userDetailsOverview: 'overview',
  userDetailsEdit: 'edit',
  userDetailsViewPhotoId: 'view-photo-id',
  userDetailsGuarantorSet: 'guarantor-set',
  userDetailsGuarantorReset: 'guarantor-reset',
  userDetailsCosignerSet: 'cosigner-set',
  userDetailsCosignerReset: 'cosigner-reset',
  userDetailsCreditScoreSubmit: 'credit-score-submit',
  userDetailsCreditScoreCancel: 'credit-score-cancel',
  userDetailsCreditScoreDownload: 'credit-score-download',
  // USER DETAILS -> ACCOUNT SCORE
  userAccountScores: 'account-scores',
  userAccountScoreData: 'data/:account_id',
  getUserAccountScoreData: (q: AccountScore.Id) => gen(routes.userAccountScoreData, q),
  userAccountScoreModels: 'models/:account_id',
  getUserAccountScoreModels: (q: AccountScore.Id) => gen(routes.userAccountScoreModels, q),
  userAccountScoreTransactions: 'transactions/:account_id',
  getUserAccountScoreTransactions: (q: AccountScore.Id) =>
    gen(routes.userAccountScoreTransactions, q),
  // USER DETAILS -> OWNERS
  userDetailsOwners: 'owners',
  userDetailsOwnersRemove: 'remove/:owner_id',
  getUserDetailsOwnersRemove: (q: Owner.Id) => gen(routes.userDetailsOwnersRemove, q),
  // USER DETAILS -> AGENT UNITS
  userDetailsAgentUnits: 'agent-units',
  userDetailsAgentUnitsEdit: 'edit/:unit_id',
  getUserDetailsAgentUnitsEdit: (q: Unit.Id) => gen(routes.userDetailsAgentUnitsEdit, q),
  // USER DETAILS -> INVITED USERS
  userDetailsInvitedUsers: 'invited-users',
  // USER DETAILS -> APPLICATIONS
  userDetailsApplications: 'applications',
  // USER DETAILS -> DOCUMENTS
  userDetailsDocuments: 'documents',
  userDetailsDocumentsUpload: 'upload',
  userDetailsDocumentsView: 'view/:document_id',
  getUserDetailsDocumentsView: (q: UserDocument.Id) => gen(routes.userDetailsDocumentsView, q),
  userDetailsDocumentsEdit: 'edit/:document_id',
  getUserDetailsDocumentsEdit: (q: UserDocument.Id) => gen(routes.userDetailsDocumentsEdit, q),
  userDetailsDocumentsDelete: 'delete/:document_id',
  getUserDetailsDocumentsDelete: (q: UserDocument.Id) => gen(routes.userDetailsDocumentsDelete, q),
  userDetailsDocumentsRawContentPages: 'raw-pages/:document_id',
  getUserDetailsDocumentsRawContentPages: (q: UserDocument.Id) =>
    gen(routes.userDetailsDocumentsRawContentPages, q),
  userDetailsDocumentsRawCombined: 'raw-combined/:document_id',
  getUserDetailsDocumentsRawCombined: (q: UserDocument.Id) =>
    gen(routes.userDetailsDocumentsRawCombined, q),

  // USER DETAILS -> LINKED ACCOUNTS
  userDetailsLinkedAccounts: 'linked-accounts',
  // USER DETAILS -> LEASES
  userDetailsLeases: 'leases',
  userDetailsLeasesDelete: 'delete/:lease_id',
  getUserDetailsLeasesDelete: (q: Lease.Id) => gen(routes.userDetailsLeasesDelete, q),
  // USER DETAILS -> CHATS
  userDetailsChats: 'chats',
  userDetailsChatsCreate: 'create',
  userDetailsChatsView: 'view/:chat_id',
  getUserDetailsChatsView: (q: Chat.Id) => gen(routes.userDetailsChatsView, q),
  userDetailsChatsDelete: 'delete/:chat_id',
  getUserDetailsChatsDelete: (q: Chat.Id) => gen(routes.userDetailsChatsDelete, q),
  // USER DETAILS -> AUDIT LOG
  userDetailsAudit: 'audit-log',

  // #endregion

  // #region OWNERS
  owners: '/owners',
  ownerCreate: '/owners/new',
  ownerEdit: 'edit/:owner_id',
  getOwnerEdit: (q: Owner.Id) => gen(routes.ownerEdit, q),

  // #endregion
  // #region OWNER
  ownerDetails: '/owners/:owner_id',
  getOwnerDetails: (q: Owner.Id) => gen(routes.ownerDetails, q),
  // OWNER DETAILS -> OVERVIEW
  ownerDetailsOverview: 'overview',
  ownerDetailsOverviewEdit: 'edit',
  // OWNER DETAILS -> ACCOUNTS
  ownerDetailsAccounts: 'accounts',
  ownerDetailsAccountsCreate: 'new',
  ownerDetailsAccountsEdit: 'edit/:owner_bank_account_id',
  getOwnerDetailsAccountsEdit: (q: PaymentAccount.Id) => gen(routes.ownerDetailsAccountsEdit, q),
  ownerDetailsAccountsDelete: 'delete/:owner_bank_account_id',
  getOwnerDetailsAccountsDelete: (q: PaymentAccount.Id) =>
    gen(routes.ownerDetailsAccountsDelete, q),
  // OWNER DETAILS -> UNITS
  ownerDetailsUnits: 'units',
  ownerDetailsUnitsEdit: 'edit/:unit_id',
  getOwnerDetailsUnitsEdit: (q: Unit.Id) => gen(routes.ownerDetailsUnitsEdit, q),
  // OWNER DETAILS -> PROPERTIES
  ownerDetailsProperties: 'properties',
  ownerDetailsPropertiesCreate: 'new',
  ownerDetailsPropertiesEdit: 'edit/:property_id',
  getOwnerDetailsPropertiesEdit: (q: Property.Id) => gen(routes.ownerDetailsPropertiesEdit, q),
  ownerDetailsPropertiesDelete: 'delete/:property_id',
  getOwnerDetailsPropertiesDelete: (q: Property.Id) => gen(routes.ownerDetailsPropertiesDelete, q),
  // OWNER DETAILS -> TEMPLATES
  ownerDetailsTemplates: 'templates',
  // OWNER DETAILS -> USERS
  ownerDetailsUsers: 'users',
  ownerDetailsUsersManage: 'manage',

  // #endregion

  // #region PROPERTY MANAGERS
  propertyManagers: '/property-managers',
  propertyManagersCreate: 'new',
  propertyManagersEdit: 'edit/:property_manager_id',
  getPropertyManagersEdit: (q: PropertyManager.Id) => gen(routes.propertyManagersEdit, q),

  // #endregion
  // #region PROPERTY MANAGER DETAILS
  propertyManager: `/property-managers/:property_manager_id`,
  getPropertyManager: (q: PropertyManager.Id) => gen(routes.propertyManager, q),
  // PROPERTY MANAGER -> OVERVIEW
  propertyManagerOverview: 'overview',
  propertyManagerOverviewEdit: 'edit',
  // PROPERTY MANAGER -> OWNERS
  propertyManagerOwners: 'owners',
  propertyManagerOwnersEdit: 'edit/:owner_id',
  getPropertyManagerOwnersEdit: (q: Owner.Id) => gen(routes.propertyManagerOwnersEdit, q),
  propertyManagerOwnersAdd: 'add',
  // PROPERTY MANAGER -> ACCOUNTS
  propertyManagerAccounts: 'accounts',
  // PROPERTY MANAGER -> USERS
  propertyManagerUsers: 'users',
  propertyManagerUsersAdd: 'add',
  propertyManagerUsersLinkOwners: ':user_id/link-owners',
  propertyManagerUsersUnlink: ':user_id/unlink',
  getPropertyManagerUsersLinkOwners: (q: User.Id) => gen(routes.propertyManagerUsersLinkOwners, q),
  getPropertyManagerUsersUnlink: (q: User.Id) => gen(routes.propertyManagerUsersUnlink, q),
  // #endregion

  // #region SUPPORT
  support: '/support',
  supportChat: ':chat_id',
  getSupportChat: (q: Chat.Id) => gen(routes.supportChat, q),

  // #endregion

  // #region LEASES
  leases: '/leases',
  leaseDelete: 'delete/:lease_id',
  getLeaseDelete: (q: Lease.Id) => gen(routes.leaseDelete, q),
  leaseContractView: 'contract-view/:lease_id',
  getLeaseContractView: (q: Lease.Id) => gen(routes.leaseContractView, q),
  leaseRenew: 'renew/:lease_id',
  getLeaseRenew: (q: Lease.Id) => gen(routes.leaseRenew, q),

  // #endregion
  // #region LEASE DETAILS
  leaseDetails: '/leases/:lease_id',
  getLeaseDetails: (q: Lease.Id) => gen(routes.leaseDetails, q),
  leaseDetailsRenew: 'renew',
  leaseDetailsRegenerate: 'regenerate-contract',
  leaseDetailsDelete: 'delete',
  leaseDetailsLock: 'lock',
  leaseDetailsDraftPreview: 'preview-draft/:draft_id',
  getLeaseDetailsDraftPreview: (q: LeaseDraft.Id) => gen(routes.leaseDetailsDraftPreview, q),
  leaseDetailsContractView: 'view-contract',
  leaseDetailsSign: 'sign',
  leaseDetailsSignatureEdit: 'edit-signature/:type',
  getLeaseDetailsSignatureEdit: (q: { type: UserSignature.Type }) =>
    gen(routes.leaseDetailsSignatureEdit, q),
  leaseDetailsPhotoIdView: 'view-photo-id/:user_id',
  getLeaseDetailsPhotoIdView: (q: User.Id) => gen(routes.leaseDetailsPhotoIdView, q),

  // LEASE DETAILS -> OVERVIEW
  leaseDetailsOverview: 'overview',
  // LEASE DETAILS -> CHECKLIST
  leaseDetailsChecklist: 'checklist',
  leaseDetailsEditChecklist: 'checklist/:item_id',
  getLeaseDetailsEditChecklist: (query: LeaseChecklist.Id) =>
    gen(routes.leaseDetailsEditChecklist, query),
  // LEASE DETAILS -> LEDGER
  leaseDetailsLedger: 'ledger',
  leaseDetailsLedgerCreateCharge: 'new-charge',
  leaseDetailsLedgerCreatePayment: 'new-payment',
  leaseDetailsLedgerDelete: 'delete/:transaction_id',
  getLeaseDetailsLedgerDelete: (q: Ledger.Id) => gen(routes.leaseDetailsLedgerDelete, q),
  leaseDetailsLedgerEdit: 'edit/:transaction_id',
  getLeaseDetailsLedgerEdit: (q: Ledger.Id) => gen(routes.leaseDetailsLedgerEdit, q),
  // LEASE DETAILS -> INVOICE
  leaseDetailsInvoice: 'invoice',
  leaseDetailsInvoiceDelete: 'delete/:transaction_id',
  getLeaseDetailsInvoiceDelete: (q: Ledger.Id) => gen(routes.leaseDetailsInvoiceDelete, q),
  leaseDetailsInvoiceEdit: 'edit/:transaction_id',
  getLeaseDetailsInvoiceEdit: (q: Ledger.Id) => gen(routes.leaseDetailsInvoiceEdit, q),
  // LEASE DETAILS -> AUDIT
  leaseDetailsAudit: 'audit-log',
  // LEASE DETAILS -> TEMPLATES
  leaseDetailsTemplates: 'templates',

  // #endregion

  // #region PAYMENTS
  payments: '/payments',
  paymentsEdit: 'edit/:transaction_id',
  getPaymentsEdit: (q: Ledger.Id) => gen(routes.paymentsEdit, q),
  paymentsDelete: 'delete/:transaction_id',
  getPaymentsDelete: (q: Ledger.Id) => gen(routes.paymentsDelete, q),

  plaidRequests: '/plaid-requests',
  getPlaidRequests: (query?: Record<string, string>) =>
    withSearchParams(routes.plaidRequests, query),
  plaidRequestDetails: '/plaid-requests/:id',
  getPlaidRequestDetails: ({ request_id: id }: { request_id: string }) =>
    gen(routes.plaidRequestDetails, { id }),

  // #endregion

  // #region CHATS
  chatManageSupport: 'manage-support',
  chatListCreate: 'create',
  chatListView: 'chats/:chat_id',
  getChatListView: (q: Chat.Id) => gen(routes.chatListView, q),
  chatFile: 'file/:file_id',
  getChatFile: (q: UserFile.Id) => gen(routes.chatFile, q),
  chatArchive: 'archive',
  chatEdit: 'edit',
  chatDelete: 'delete',
  chatListDelete: 'delete/:chat_id',
  getChatListDelete: (q: Chat.Id) => gen(routes.chatListDelete, q),
  // #endregion
} as const

const withSearchParams = (path: string, query?: Record<string, string>) =>
  path + (query ? '?' + new URLSearchParams(query).toString() : '')

export const adminRoutes = routes
