import { useLocation, useNavigation } from 'react-router-dom'

type TransitionFilter = (from: string, to: string) => boolean
const defaultTransitionFilter: TransitionFilter = () => true

export const useTransitionBetweenRoutes = (include: TransitionFilter = defaultTransitionFilter) => {
  const navigation = useNavigation()
  const location = useLocation()
  const loading = navigation.state === 'loading'
  const destination = loading ? navigation.location?.pathname : null
  return !!(loading && destination && include(location.pathname, destination))
}
