import { SupportNotificationProvider } from '@rello/chat'
import { AdminUser } from 'api/admin'
import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { MobileMenu } from 'components/mobile-menu'
import { useUser } from 'components/use-user'
import { UserMenu } from 'components/user-menu'
import { adminRoutes } from 'const/admin-routes'
import { FC } from 'react'
import { MediaQuery, Outlet } from 'ui'
import { getHomeRoute } from 'utils/get-home-route'
import styles from './layout.module.css'

export const Layout: FC = () => {
  const [user] = useUser()
  const logoLinkTo = getHomeRoute(user)

  const children = (
    <div className={styles.container}>
      <Header logoLinkTo={logoLinkTo}>
        <MediaQuery.Desktop>
          <UserMenu
            accountLinkTo={adminRoutes.account}
            chatLinkTo={adminRoutes.communication}
            className={styles.usermenu}
          />
        </MediaQuery.Desktop>
        <MediaQuery.NonDesktop>
          <MobileMenu className={styles.usermenu} logoLinkTo={logoLinkTo} />
        </MediaQuery.NonDesktop>
      </Header>
      <div className={styles.content}>
        <Outlet />
      </div>
      <Footer logoLinkTo={logoLinkTo} />
    </div>
  )

  return user && AdminUser.hasRoleSupport(user) ? (
    <SupportNotificationProvider currentUser={user}>{children}</SupportNotificationProvider>
  ) : (
    children
  )
}
