import { CurrentUser, UserRole } from 'api/admin'
import { useUser } from 'components/use-user'
import { adminRoutes } from 'const/admin-routes'
import { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { Access, validateRoleAccess } from 'utils/access'

interface Props {
  out?: string | null | false
  hideWhileLoading?: boolean
  children?: ReactNode
  replace?: boolean
  access?: `${Access}`
  roles?: UserRole[]
}

export const SignedIn: FC<Props> = ({
  out = adminRoutes.login,
  access,
  roles,
  replace,
  children,
  hideWhileLoading,
}) => {
  const [user, loading] = useUser()
  if (!loading && !validateAccess({ user, access, roles })) {
    return out ? <Navigate to={out} replace={replace} /> : null
  }
  return loading && hideWhileLoading ? null : <>{children}</>
}

function validateAccess({
  user,
  access,
  roles,
}: {
  user?: CurrentUser | null
  access?: `${Access}`
  roles?: `${UserRole}`[]
}) {
  if (!user) return false
  if (access && !validateRoleAccess(user, access)) return false
  if (roles && !user.roles?.some((x) => roles.includes(x))) return false
  return true
}

export const useAccess = (access: `${Access}`) => {
  const [user, loading] = useUser()
  return loading || !user ? false : validateRoleAccess(user, access)
}
